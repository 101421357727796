.faqContainer {
  max-width: 1300px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.faqTitle {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 40px;
  font-weight: 600;
  text-align: center;
  color: #333;
}

.faqList {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 700px;
  height: 715px;
  margin: 0 auto;
  overflow-y: auto;
}

.faqItem {
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  border-bottom: 1px solid #e6e6e6;
}

.faqQuestion {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: left;
  padding: 20px 0;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  color: #0066cc;
  transition: all 0.3s ease;
}

.faqQuestion:hover {
  color: #004d99;
}

.faqAnswer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, padding 0.3s ease;
}

.faqContent {
  color: #666;
  font-size: 18px;
  line-height: 1.6;
  padding-bottom: 20px;
}

.faqContent a {
  color: #0066cc;
  text-decoration: underline;
}

.faqContent a:hover {
  color: #004d99;
}

.open {
  max-height: 500px; /* Adjust based on your content */
}

.arrow {
  font-size: 12px;
  transition: transform 0.3s ease;
}

.divider {
  height: 1px;
  background-color: #e6e6e6;
  width: 100%;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .faqTitle {
    font-size: 36px;
    margin-bottom: 30px;
  }

  .faqList {
    width: 100%;
    height: auto;
    max-height: 800px;
    padding: 0 15px;
  }

  .faqQuestion {
    font-size: 18px;
    padding: 15px 0;
  }

  .faqContent {
    font-size: 16px;
  }

  .faqContainer {
    padding: 30px 15px;
  }
}

@media (max-width: 480px) {
  .faqTitle {
    font-size: 28px;
    margin-bottom: 20px;
  }

  .faqList {
    padding: 0 10px;
  }

  .faqQuestion {
    font-size: 16px;
    padding: 12px 0;
  }

  .faqContent {
    font-size: 14px;
    line-height: 1.4;
  }
}
