.landing_banner_main_wrapper {
  width: 100%;
  background-color: #fafafa;
}

.landing_main_section_container {
  margin-bottom: 0px;
  margin-top: 0px;
  background-size: cover;
  background-image: url("https://res.cloudinary.com/dgjordf6e/image/upload/v1726233443/main-section-background_q4gnqh.png");
  position: relative;
  min-height: 100vh; /* Ensure full viewport height initially */
}

.customeImage {
  width: 92px;
  height: 92px;
  object-fit: contain;
}

.landing_main_section_container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Change to 100% to match the container's height */
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.landing_main_section_content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5%;
}

.landing_main_section_inner {
  width: 100%;
  max-width: 1300px;
  margin-top: 130px;
  display: flex;
  justify-content: space-between; /* Changed from center to space-between */
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
  z-index: 2;
  margin-bottom: 80px;
  gap: 10px; /* Added gap to control spacing between sections */
}

.landing_main_section_text {
  flex: 1 1 40%; /* Adjusted from 35% to 40% */
  padding-right: 10px; /* Reduced from 20px to 10px */
  box-sizing: border-box;
}

.landing_main_section_image {
  flex: 1 1 55%; /* Adjusted from 65% to 55% */
  text-align: end;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end; /* Ensure the image aligns to the right */
}

.landing_image {
  width: 100%;
  max-width: 650px; /* Increased from 550px to 650px */
  height: auto;
}

.landing_main_section-heading {
  text-transform: capitalize;
  color: white;
  font-size: 55px;
  max-width: 500px;
}

.highlight {
  background-color: #1468ba;
  padding: 0px 10px;
  color: #6fe5ff;
}

.landing_main_section_description {
  color: white;
  text-transform: capitalize;
}

.btn_landing_wrapper {
  margin-top: 50px;
}

.btn_landing {
  height: 44px; /* Same height for both buttons */
  background-color: #1468ba;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 0 24px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.btn_landing:hover {
  background-color: #0d5ba9;
}

.number-container-wrapper {
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 15px;
  flex-wrap: wrap;
}

.number-container {
  width: 108px;
  height: 34px;
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
}

.number-container img {
  width: 18px;
  height: auto;
}

.number-text {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #f68b1f;
  text-align: right;
}

.number-text-para {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: white;
  max-width: 65%;
  line-height: 1.4;
}

.demo-button {
  height: 44px; /* Reduced from 50px to match the reference */
  background-color: #ff7a00;
  color: #fff;
  border: none;
  border-radius: 6px;
  padding: 0 24px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
}

.demo-button:hover {
  background-color: #e66e00;
}

.demo-button:active {
  background-color: #cc6200;
  transform: scale(0.98);
}

/* Optional: Add focus state for accessibility */
.demo-button:focus {
  outline: 2px solid #ff9933;
  outline-offset: 2px;
}

/* Add these styles to your existing CSS file */

.buttons-container {
  display: flex;
  gap: 15px;
  align-items: center;
  width: 100%;
}

.demo-button {
  height: 50px;
  background-color: #ff7a00;
  color: #fff;
  border: none;
  border-radius: 6px;
  padding: 12px 20px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
  width: auto;
  max-width: 160px;
}

/* Make sure the btn_landing doesn't override these layout properties */
.btn_landing_wrapper .btn_landing {
  margin-right: 0;
  height: 50px; /* Ensure same height */
  padding: 12px 25px; /* Adjust padding if needed */
}

/* Media query for mobile view */
@media (max-width: 768px) {
  .buttons-container {
    flex-direction: column; /* Stack buttons vertically on smaller screens */
    align-items: stretch; /* Stretch buttons to full width */
  }

  .btn_landing,
  .demo-button {
    width: 100%; /* Full width buttons */
    max-width: none; /* Remove max-width */
    margin-bottom: 10px; /* Add spacing between buttons */
  }
}

/* Media queries */
@media screen and (max-width: 1230px) {
  .landing_main_section-heading {
    font-size: 50px;
  }
  .landing_image {
    max-width: 600px; /* Added responsive adjustment */
  }
}

@media screen and (max-width: 1150px) {
  .landing_main_section-heading {
    font-size: 45px;
  }
  .landing_image {
    max-width: 550px; /* Added responsive adjustment */
  }
}

@media screen and (max-width: 950px) {
  .landing_main_section-heading {
    font-size: 40px;
  }
}

@media screen and (max-width: 800px) {
  .landing_main_section-heading {
    font-size: 35px;
  }
}

@media screen and (max-width: 680px) {
  .landing_main_section_inner {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .customeImage {
    width: 52px;
    height: 52px;
  }

  .landing_main_section_text {
    text-align: center;
    padding-right: 0;
    max-width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .landing_main_section_image {
    display: none;
  }

  .btn_landing_wrapper {
    margin-top: 20px;
  }
}

@media screen and (max-width: 768px) {
  .number-container-wrapper {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .number-text-para {
    max-width: 100%;
    margin-top: 10px;
  }
}

/* 
@media screen and (max-width: 560px) {
  .landing_main_section-heading {
    font-size: 30px;
  }
}
@media screen and (max-width: 420px) {
  .landing_main_section_inner {
    margin-bottom: 82px;
  }
} */

.number-text-animating {
  transition: color 0.3s ease;
  color: #f68b1f; /* Use your brand color for highlighting during animation */
  font-weight: bold;
}

.buttons-container {
  display: flex;
  gap: 15px;
  align-items: center;
  width: 100%;
}

.btn_landing_wrapper .btn_landing,
.btn_landing_wrapper .demo-button {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.btn_landing_wrapper .demo-button {
  background-color: #ff7a00;
  color: #fff;
  border: none;
  border-radius: 6px;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  white-space: nowrap;
  width: auto;
  max-width: 160px;
}

/* Media query for mobile view */
@media (max-width: 768px) {
  .buttons-container {
    flex-direction: column;
    width: 100%;
  }

  .btn_landing_wrapper .btn_landing,
  .btn_landing_wrapper .demo-button {
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }

  .btn_landing_wrapper a {
    width: 100%;
  }
}
