.community_wrapper_main_outer {
  background-color: #0055aa;
  width: 100%;
  padding: 40px 20px 100px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.community_wrapper_main_inner {
  width: 100%;
  max-width: 1200px;
}

.community_wrapper_counter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.counter_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
}

.counter_value {
  font-size: 56px;
  font-weight: 700;
  color: #ffffff;
  margin: 0;
  line-height: 1.2;
  font-family: "Poppins", sans-serif;
}

.counter_label {
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  margin: 10px 0 0 0;
  text-align: center;
  font-family: "Poppins", sans-serif;
}

.center_counter_box {
  /* border-left: 1px solid rgba(255, 255, 255, 0.3);
    border-right: 1px solid rgba(255, 255, 255, 0.3); */
  margin: 0 40px;
  padding: 0 60px;
}

.community_wrapper_title {
  font-size: 40px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  font-family: "Inter", sans-serif;
}

@media screen and (max-width: 1200px) {
  .community_wrapper_main_inner {
    max-width: 90%;
  }
}

@media screen and (max-width: 820px) {
  .community_wrapper_main_outer {
    padding: 30px 15px 60px 15px;
  }

  .counter_value {
    font-size: 48px;
  }

  .counter_label {
    font-size: 16px;
  }

  .center_counter_box {
    margin: 0 20px;
    padding: 0 40px;
  }
}

@media screen and (max-width: 640px) {
  .community_wrapper_main_outer {
    padding: 25px 10px 50px 10px;
  }

  .community_wrapper_title {
    font-size: 36px;
  }


  .counter_value {
    font-size: 40px;
  }

  .counter_label {
    font-size: 14px;
  }

  .center_counter_box {
    margin: 0 15px;
    padding: 0 30px;
  }
}

@media screen and (max-width: 480px) {
  .community_wrapper_main_outer {
    padding: 20px 10px 40px 10px;
  }

  .community_wrapper_counter {
    flex-direction: column;
    gap: 30px;
    align-items: center;
  }

  .community_wrapper_title {
    font-size: 25px;
  }


  .counter_value {
    font-size: 46px;
  }

  .counter_label {
    font-size: 16px;
  }

  .center_counter_box {
    border-left: none;
    border-right: none;
    /* border-top: 1px solid rgba(255, 255, 255, 0.3);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3); */
    margin: 15px 0;
    padding: 30px 0;
    width: 100%;
    max-width: 280px;
  }

  .counter_box {
    padding: 0;
    width: 100%;
    max-width: 280px;
  }
}
