.badge_box_compo_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    padding: 40px;
    background-color: #f7f7f7;
    width: 345px;
    height: 350px;
    text-align: center;
    border-radius: 40px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  }
  
  .icon_badge_card_box {
    background-color: white;
    border-radius: 50%;
    width: 110px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
  }
  
  .icon_badge_card_box img {
    width: 65px;
    height: 65px;
    object-fit: contain;
  }
  
  .badge_heading {
    font-size: 22px;
    font-weight: 530;
    margin: 0 0 10px 0;
    color: #333;
    font-family: 'Arial', sans-serif;
  }
  
  .badge_info {
    font-size: 14px;
    line-height: 1.5;
    color: #666;
    margin: 0;
    font-family: 'Arial', sans-serif;
  }
  
  @media screen and (max-width: 859px) {
    .badge_box_compo_wrapper {
      width: 320px;
      padding: 35px 20px;
      height: 330px;
      border-radius: 35px;
    }
  }
  
  @media screen and (max-width: 643px) {
    .badge_box_compo_wrapper {
      width: 345px;
      height: 350px;
      min-height: 320px;
      margin: 10px;
      border-radius: 40px;
    }
  }
  
  @media screen and (max-width: 439px) {
    .badge_box_compo_wrapper {
      width: 300px;
      height: 330px;
      margin: 10px;
      border-radius: 35px;
    }
  }