.container {
  width: 450px;
  height: 410px;
  padding: 0 15px;
}

.card {
  max-width: 380px;
  width: 100%;
  padding: 25px 30px;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
  max-height: 385px;
  height: 100%;
}

.title {
  font-size: 30px;
  font-weight: 500;
  color: #222;
  text-transform: uppercase;
  line-height: 1.3;
  margin: 0;
  text-align: center;
  margin-top: 10px;
}

.priceContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px 0 20px 0;
}

.dollarSign {
  font-size: 45px;
  font-weight: bold;
  margin-right: 5px;
}

.amount {
  font-size: 70px;
  font-weight: bold;
  line-height: 1;
}

.description {
  font-size: 22px;
  color: #333;
  margin: 0 0 2px 0;
  text-align: center;
  font-weight: bold;
  line-height: 0.95;
}

.subDescription {
  font-size: 12px;
  color: #666;
  text-align: center;
  margin: 0 0 30px 0;
}

.button {
  display: block;
  width: 300px;

  padding: 16px 30px;
  border: none;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 500;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: auto;
}

.button:hover {
  opacity: 0.9;
}
