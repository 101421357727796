.container {
  background: #f5f5f5;
  border-radius: 10px;
  padding: 90px;
  width: 1300px;
  height: 567px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: -50px auto;
}

.contentWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
  margin-right: 30px;
}

.imageWrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  max-width: 100%;
  height: auto;
}

.title {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 38px;
  line-height: 50px;
  color: #1b1c1f;
  max-width: 440px;
}

.description {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #1b1c1f;
  max-width: 413px;
  margin-top: -10px;
}

.contactButton {
  background: #1468ba;
  border-radius: 5px;
  width: 212px;
  height: 60px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.contactButton:hover {
  background: #1257a0;
}

.buttonText {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 22px;
  color: #ffffff;
}

/* Mobile responsiveness */
@media (max-width: 1300px) {
  .container {
    width: 90%;
    padding: 60px;
    padding: 60px 40px;
    margin: -30px auto;
  }
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    height: auto;
    padding: 40px;
    padding: 40px 30px;
  }

  .contentWrapper {
    margin-right: 0;
    margin-bottom: 30px;
    margin-bottom: 40px;
    max-width: 100%;
  }
}

@media (max-width: 992px) {
  .container {
    padding: 50px 30px;
    margin: -20px auto;
    height: auto;
  }

  .title {
    font-size: 32px;
    line-height: 42px;
  }

  .description {
    font-size: 18px;
  }

  .contactButton {
    width: 180px;
    height: 50px;
  }

  .buttonText {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    padding: 40px 30px;
  }

  .contentWrapper {
    margin-right: 0;
    margin-bottom: 40px;
    max-width: 100%;
  }

  .imageWrapper {
    width: 100%;
    max-width: 500px;
  }

  .image {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 30px 20px;
    margin: -10px auto;
  }

  .title {
    font-size: 28px;
    line-height: 36px;
    max-width: 100%;
  }

  .description {
    font-size: 16px;
    max-width: 100%;
  }

  .contactButton {
    width: 100%;
    max-width: 212px;
  }
}
