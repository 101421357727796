.progress-modal-text {
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 20px;
  z-index: 1000;
}
.progress-inspection-text {
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}
.progress-modal-btn {
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  margin-top: 20px;
}
.progress-modal-btn-no {
  width: 120px;
  border-radius: 100px;
  border: 1px solid #1468ba;
  text-align: center;
  padding: 5px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}
.progress-modal-btn-yes {
  color: white !important;
  width: 120px;
  border-radius: 100px;
  background: linear-gradient(90deg, #ff7a00 0%, #ff9900 94.74%);
  text-align: center;
  padding: 5px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.progress-modal .ant-modal-content {
  height: 160px !important;
  cursor: pointer;
}
