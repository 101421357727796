/* Company section styles */
.selectCompanyHeading {
  font-size: 17px;
  font-weight: 900;
  padding: 15px;
}

.selectCompanyHeadingError {
  font-size: 17px;
  font-weight: 900;
  padding: 15px;
  color: red;
}

/* Grid layout for companies */
.companiesGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  padding: 0 20px; /* Increase padding on both sides */
  max-width: 95%; /* Limit the width to create space on sides */
  margin: 0 auto; /* Center the grid */
}

/* Company box styles */
.companyBox {
  position: relative;
  border: 1px solid #cfdeed;
  border-radius: 10px;
  padding: 10px;
  height: 90px; /* Make boxes smaller */
  width: 100%; /* Use full width of grid cell */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  cursor: pointer;
  box-shadow: 0px 10px 30px 0px #0000001a; /* Add specified box shadow */
}

.companyBoxSelected {
  border: 2px solid #ff7a00;
  box-shadow: 0 0 8px rgba(255, 122, 0, 0.3);
}

.companyDisabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.companyLogo {
  width: 50px; /* Reduce logo size */
  height: 50px;
  margin-bottom: 5px; /* Reduce margin */
  object-fit: contain;
}

.companyNameText {
  color: #0b5eaf;
  font-size: 12px; /* Reduce font size */
  font-weight: 700;
  text-align: center;
  margin-top: 5px; /* Reduce margin */
}

.companyNameTextDisabled {
  color: gray;
  font-size: 12px; /* Reduce font size */
  font-weight: 700;
  text-align: center;
  margin-top: 5px; /* Reduce margin */
}

.tickContainer {
  position: absolute;
  top: 5px; /* Adjust position */
  right: 5px; /* Adjust position */
  z-index: 2;
}

.tickBackgroundColor {
  width: 18px; /* Reduce size */
  height: 18px;
  background-color: #f1f1f1;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectCompanyCheckboxContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-top: 1px solid #cfdeed;
  margin-bottom: 5px !important;
}

.selectCompanyChecksContainer {
  height: calc(100vh - 350px);
  min-height: 400px;
  overflow-y: auto;
  box-sizing: border-box;
  padding-bottom: 70px; /* Add space for the fixed button */
  position: relative;
  z-index: 1; /* Lower z-index for the scrollable content */
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

/* Button styles */
.inpectionInstructionBtnContainer {
  position: fixed;
  bottom: 10px;
  width: 29.899%;
  background-color: white;
  padding: 10px 0;
  z-index: 10; /* Higher z-index for the button container */
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.05); /* Add shadow for visual separation */
}

.inspectionBtnAlign {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inspectionBtnContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  margin-top: 15px;
  background: linear-gradient(90deg, #ff7a00 0%, #ff9900 94.74%);
  border-radius: 100px;
  width: 150px;
  cursor: pointer;
}

.selectText {
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
}

.loaderSpinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 21px;
}

/* Add back button styles */
.backButtonContainer {
  padding: 10px 20px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.backButton {
  background: transparent;
  border: none;
  color: #246db5;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;
  border: 1px solid #e1e1e1;
}

.backButton:hover {
  color: #ff7a00;
  background-color: #f9f9f9;
}

/* Media queries */
@media only screen and (max-width: 1366px) {
  .selectCompanyChecksContainer {
    height: calc(100vh - 400px);
  }

  .companiesGrid {
    grid-template-columns: repeat(2, 1fr);
    max-width: 90%;
  }
}

@media only screen and (max-width: 1024px) {
  .inpectionInstructionBtnContainer {
    width: 60%;
  }

  .selectCompanyChecksContainer {
    height: calc(100vh - 380px);
  }

  .companiesGrid {
    max-width: 95%;
  }
}

/* Modify this media query to keep 2 columns */
@media only screen and (max-width: 768px) {
  .inpectionInstructionBtnContainer {
    width: 90%;
  }

  .selectCompanyChecksContainer {
    height: calc(100vh - 370px);
  }

  /* Keep 2 columns but make boxes smaller */
  .companiesGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    padding: 0 15px; /* Add padding on both sides */
  }

  .companyBox {
    padding: 8px;
    height: 85px; /* Reduce height */
  }

  .companyLogo {
    width: 40px; /* Reduce logo size */
    height: 40px;
  }

  .companyNameText,
  .companyNameTextDisabled {
    font-size: 11px; /* Reduce font size */
    margin-top: 4px; /* Reduce margin */
  }

  .tickContainer {
    top: 4px; /* Adjust position */
    right: 4px; /* Adjust position */
  }

  .tickBackgroundColor {
    width: 16px; /* Reduce size */
    height: 16px;
  }
}

@media only screen and (max-width: 480px) {
  /* Even on very small screens, keep 2 columns */
  .companiesGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    padding: 0 10px; /* Add padding on both sides */
  }

  .companyBox {
    padding: 6px;
    height: 75px; /* Reduce height */
    box-shadow: 0px 5px 15px 0px #0000001a; /* Smaller shadow for mobile */
  }

  .companyLogo {
    width: 35px; /* Reduce logo size */
    height: 35px;
    margin-bottom: 4px; /* Reduce margin */
  }

  .companyNameText,
  .companyNameTextDisabled {
    font-size: 10px; /* Reduce font size */
  }
}

@media only screen and (max-width: 600px) {
  .inpectionInstructionBtnContainer {
    width: 100%;
    background-color: white;
    height: 120px;
    margin-bottom: -50px;
    z-index: 10; /* Ensure z-index is applied on mobile as well */
  }

  .selectCompanyCheckboxContainer {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .selectCompanyChecksContainer {
    height: calc(100vh - 350px);
  }
}

@media only screen and (min-width: 1500px) {
  .selectCompanyChecksContainer {
    height: calc(100vh - 450px);
    overflow-y: auto;
  }

  .companiesGrid {
    grid-template-columns: repeat(3, 1fr);
    max-width: 90%;
  }
}
