.register-main-container {
  width: 100%;
  /* position: absolute; */
  /* height: 100; */
  position: absolute;
  height: auto !important;
}
.register-background-image {
  position: absolute;
  width: 100%;
  height: 960px !important;

  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center center;
  background: url("../../Assets//bg_img.png");
  padding-bottom: 10px;
}
.modal-styling {
  left: 0 !important;
  padding: 0 !important;
  right: 0 !important;
  width: 100%;
  height: 100%;
  overflow: scroll;
}
.register-checkbox-text {
  color: #ffffff;
}
.register-container {
  display: flex;
  justify-content: center;
}

.checkmark::before {
  display: none !important;
}
.checkmark::after {
  left: 4px !important;
  top: -7px !important;
  width: 11px !important;
  height: 25px !important;
  border: 1px solid #fc6522 !important;
  border-width: 0 3px 3px 0 !important;
}

@media screen and (min-width: 768px) {
  .register-background-image {
    background-size: cover;
    background-position: center center;
  }
}

/* @media only screen and (max-width: 800px) {
  .button-wrapper {
    width: 100% !important;
  }
} */

.register-mainBgSignin {
  /* background: linear-gradient(0deg, rgba(27, 104, 179, 0.6) 0%, #1b68b3 99.97%); */
  position: relative;
  width: 100%;
  height: 900px;
  padding: 20px 0;
}
.register-contentMainScreen {
  width: 100%;
}
.register-loginPageH1 {
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 86px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #ffffff;
  padding-top: 40px;
  padding-bottom: 0px;
  margin: 0px;
}
.register-logoSpanColor {
  color: #ff9900;
}
.register-mainScreenP {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 27px;
  letter-spacing: 0.18em;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;
}
.intro-validation-text {
  text-align: center;
  font-size: 10px;
  font-weight: 300;
  color: #dadada;
  letter-spacing: 0.12px;
}
.intro-state-name {
  color: #ff7400;
  font-weight: 600;
}
.register-contentFooterAreaSignin {
  text-align: center;
  width: 90%;
  margin: 0 auto;
  margin-top: 20px;
}

.registration-warn-message {
  background: rgb(247, 178, 22);
  color: white;
  text-align: center;
  font-size: 9px;
  border-radius: 4px;
  margin-bottom: 13px;
}
.register-signinHeadingH3 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
  margin-top: 40px;
  margin-bottom: 20px;
}
.warn-message-wrapper {
  margin: auto;
  width: 300px;
}

.tool-tip {
  position: absolute;
  right: 19px;
  z-index: 999999999999999;
  bottom: 39px;
}
@media only screen and (min-width: 1900px) {
  .ant-form-item .ant-select,
  .ant-form-item .ant-cascader-picker {
    /* width: 505px !important; */
  }
  .ant-form-item-control-input {
    /* width: 505px !important; */
  }
  .registration-warn-message {
    width: 50%;
  }
}

@media only screen and (max-width: 600px) {
  .warn-message-wrapper {
    width: 100%;
  }
}

.phone-input-field {
  background: rgb(231, 238, 245);
  border: 1px solid rgb(231, 239, 248);
  box-sizing: border-box;
  border-radius: 3px;
  width: 100%;
  margin: 0px auto 15px;
  padding: 15px 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  font-family: "Poppins";
  letter-spacing: 1px;
}
