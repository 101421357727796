.formSection {
  /* Keep the original background image */
  background-color: #fafafa;
  background-image: url("https://res.cloudinary.com/dgjordf6e/image/upload/v1741757740/auto-mechanic-inspecting-car-with-clipboard_1_wknoct.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.overlay {
  min-height: 100vh;
  display: flex;
  align-items: stretch;
  position: relative;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.3);
  width: "100%";
  height: "100%";
}

.layout {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  max-width: 1300px;
  margin: 0 auto;
  width: 100%;
  gap: 20px;
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.formGlass {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 40px;
  width: 100%;
  max-width: 690px;
  border: 2px solid #ffffff33;
  margin: 0 auto;
}

.heading {
  font-size: 80px;
  font-weight: 600;
  color: white;
  margin-bottom: 20px;
  line-height: 1.2;
  text-transform: uppercase;
}

.formHeading {
  font-size: 40px;
  text-align: center;
  font-weight: 600;
  font-family: Poppins, sans-serif;
  color: #ffffff;
  margin: 0 0 30px 0;
  line-height: 1.2;
}

.formHeading span {
  color: #ff9900;
}

.formWrapperMain {
  width: 100%;
}

.namingSections {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px; /* Ensures space between fields */
  margin-bottom: 15px;
  width: 100%;
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
}

.formItem {
  width: 100%;
  margin-bottom: 15px !important;
}

.formItemFirst {
  width: calc(50% - 7.5px); /* Ensures proper spacing */
  min-width: 150px; /* Prevents items from getting too small */
  margin-bottom: 0 !important;
}

.inputField {
  background-color: transparent !important;
  border: 1px solid rgba(255, 255, 255, 0.5) !important;
  box-sizing: border-box !important;
  border-radius: 8px !important;
  width: 100% !important;
  margin: 0 !important;
  padding: 15px 20px !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  font-family: "Poppins", sans-serif !important;
  letter-spacing: 0.5px !important;
  color: white !important;
}

.inputField::placeholder {
  color: #d2d2d2 !important;
  font-size: 16px !important;
}

.inputField:focus {
  box-shadow: 0 0 0 2px rgba(255, 153, 0, 0.2) !important;
}

/* Override antd specific styles */
:global(.ant-input-affix-wrapper) {
  background-color: transparent !important;
  border: 1px solid rgba(255, 255, 255, 0.5) !important;
  color: white !important;
}

:global(.ant-input-affix-wrapper input) {
  background-color: transparent !important;
  color: white !important;
}

:global(.ant-input-affix-wrapper-focused) {
  box-shadow: 0 0 0 2px rgba(255, 153, 0, 0.2) !important;
}

:global(.ant-input-password-icon) {
  color: rgba(255, 255, 255, 0.7) !important;
}

.checkboxContainer {
  margin-bottom: 25px;
  margin-top: 10px;
}

.checkboxInner {
  display: flex;
  align-items: center;
}

.checkboxLabel {
  position: relative;
  padding-left: 35px;
  margin-bottom: 0;
  height: 24px;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
}

.checkboxLabel input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 3px;
}

.checkboxLabel:hover input ~ .checkmark {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.checkboxLabel input:checked ~ .checkmark {
  background-color: transparent;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkboxLabel input:checked ~ .checkmark:after {
  content: "\2713";
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: white;
  height: 24px;
  width: 24px;
}

.termsText {
  color: white;
  padding-left: 10px;
  font-size: 14px;
}

.termsLink {
  color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
}

.submitButton {
  background: #ff9900 !important;
  border-radius: 8px !important;
  border: none !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  text-align: center !important;
  color: #ffffff !important;
  text-decoration: none !important;
  cursor: pointer !important;
  display: block;
  width: 100% !important;
  margin: 0 auto !important;
  height: 48px !important;
}

.submitButton:hover {
  background-color: #f08c00 !important;
  color: #ffffff !important;
}

.loginLink {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  color: white;
  font-size: 14px;
}

.loginTextLink {
  color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
  margin-left: 5px;
}

@media screen and (max-width: 992px) {
  .formSection {
    flex-direction: column;
  }

  .leftPanel,
  .rightPanel {
    width: 100%;
    padding: 40px 20px;
  }

  .leftPanel {
    padding-bottom: 0;
  }

  .heading {
    font-size: 36px;
    text-align: center;
  }

  .formGlass {
    padding: 30px 20px;
  }

  .formHeading {
    font-size: 24px;
    text-align: center;
  }
}

@media screen and (max-width: 576px) {
  .namingSections {
    flex-direction: column;
    gap: 15px;
  }

  .formItemFirst {
    width: 100%; /* Full width on smaller screens */
  }
}

/* Enhanced responsive styles */
@media screen and (max-width: 1200px) {
  .heading {
    font-size: 54px;
  }

  .formGlass {
    max-width: 400px;
  }
}

@media screen and (max-width: 992px) {
  .formSection {
    flex-direction: column;
  }

  .leftPanel,
  .rightPanel {
    width: 100%;
    padding: 40px 20px;
  }

  .leftPanel {
    padding-bottom: 0;
    min-height: 300px;
  }

  .heading {
    font-size: 42px;
    text-align: center;
  }

  .formGlass {
    padding: 30px 25px;
    max-width: 500px;
  }

  .formHeading {
    font-size: 28px;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .leftPanel {
    min-height: 220px;
    padding: 30px 15px;
  }

  .rightPanel {
    padding: 30px 15px 40px;
  }

  .heading {
    font-size: 36px;
  }

  .formGlass {
    padding: 25px 20px;
  }

  .formHeading {
    font-size: 24px;
    margin-bottom: 25px;
  }

  .inputField {
    padding: 12px 15px !important;
  }

  .layout {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

@media screen and (max-width: 576px) {
  .namingSections {
    flex-direction: column;
    gap: 15px;
    margin-bottom: 0;
  }

  .formItemFirst {
    width: 100%;
    margin-bottom: 0 !important;
  }

  .heading {
    font-size: 32px;
  }

  .leftPanel {
    min-height: 180px;
    padding: 25px 15px;
  }

  .checkboxContainer {
    margin-bottom: 20px;
  }

  .termsText {
    font-size: 13px;
  }
}

@media screen and (max-width: 480px) {
  .heading {
    font-size: 28px;
  }

  .formHeading {
    font-size: 22px;
    margin-bottom: 20px;
  }

  .formGlass {
    padding: 20px 15px;
  }

  .inputField {
    font-size: 14px !important;
  }

  .submitButton {
    height: 44px !important;
    font-size: 16px !important;
  }

  .loginLink {
    font-size: 13px;
  }
}

@media screen and (max-width: 360px) {
  .heading {
    font-size: 24px;
  }

  .formHeading {
    font-size: 20px;
  }

  .checkboxLabel {
    padding-left: 30px;
  }

  .checkmark {
    height: 20px;
    width: 20px;
  }

  .checkboxLabel input:checked ~ .checkmark:after {
    height: 20px;
    width: 20px;
    font-size: 14px;
  }
}

/* Fix for very tall screens */
@media screen and (min-height: 900px) {
  .formSection {
    justify-content: center;
  }
}

/* Handle landscape mode on mobile */
@media screen and (max-height: 600px) and (orientation: landscape) {
  .formSection {
    height: auto;
    min-height: 100vh;
  }

  .leftPanel {
    display: none;
  }

  .rightPanel {
    width: 100%;
    padding: 30px;
  }
}
