.pricingSection {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.pricingSectionTitle {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
}

.pricingSectionPara {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 40px;
  color: #444;
}

.cardsContainer {
  display: flex;
  justify-content: center;
  align-items: stretch;
  /* flex-wrap: wrap; */
  gap: 20px;
  width: 100%;
  margin-bottom: 40px;
}

.container {
  width: 100%;
  max-width: 925px;
  background: #f5f5f5;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* margin-top: 40px; */
  margin-bottom: 40px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 15px;
}

.item {
  display: flex;
  align-items: center;
  gap: 16px;
  min-width: 250px;
  flex: 1;
}

.icon {
  width: 28px;
  height: 28px;
  flex-shrink: 0;
}

.text {
  font-family: Inter, sans-serif;
  font-size: 22px;
  font-weight: 400;
  color: #1b1c1f;
  line-height: 150%;
}

/* Media queries for responsiveness */
@media (max-width: 1440px) {
  .cardsContainer {
    flex-wrap: wrap;
  }
}
@media (max-width: 768px) {
  .row {
    flex-direction: column;
    align-items: flex-start;
  }

  .item {
    width: 100%;
    justify-content: flex-start;
  }

  .text,
  .price {
    font-size: 18px;
  }

  .pricingSectionTitle {
    font-size: 30px;
  }

  .pricingSectionPara {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 15px;
  }

  .text,
  .price {
    font-size: 16px;
  }
}
