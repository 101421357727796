.inspection-process {
  max-width: 1300px;
  margin: 0 auto;
  padding: 15px;
  font-family: "Inter", sans-serif;
}

.ride-share-title {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #f68b1f;
  color: #fff;
  padding: 5px 10px;
  border-radius: 58.57px;
  display: inline-block; /* Ensures better handling on small screens */
}

.inspection-layout {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 80px;
}

.inspection-image-section {
  flex: 0 0 35%;
  max-width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inspection-image-section img {
  width: 100%;
  /* max-width: 300px; */
  height: auto;
  object-fit: contain;
}

.inspection-content-section {
  flex: 0 0 50%;
  max-width: 50%;
}

.main-title {
  font-size: 28px;
  font-weight: 600;
  color: #1e1e1e;
  margin-bottom: 30px;
  margin-top: 15px;
  line-height: 1.2;
  text-align: left;
  align-items: center;
}

.steps-container {
  position: relative;
  padding-right: 10px;
}

.timeline-line {
  position: absolute;
  left: 39px;
  top: 60px;
  bottom: 200px;
  width: 1px;
  background-color: transparent;
  border-left: 2px dashed #aec0ce;
}

.step-item {
  display: flex;
  gap: 30px;
  align-items: start;
  position: relative;
  padding: 10px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.step-item:hover {
  background-color: #1468ba1a;
}

.icon-container-progression {
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  background-color: #1468ba;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(20, 104, 186, 0.2);
}

.step-icon {
  width: 60%;
  height: 60%;
  object-fit: contain;
  filter: brightness(0) invert(1); /* Makes the icon white */
}

.step-content {
  flex-grow: 1;
  max-width: 480px;
}

.step-number {
  display: block;
  font-size: 14px;
  font-weight: 700;
  color: #979797;
  line-height: 160%;
  margin-bottom: 0px;
}

.step-title {
  font-size: 24px;
  font-weight: 700;
  color: #1e1e1e;
  margin: 0 0 8px 0;
}

.step-description {
  font-size: 18px;
  font-weight: 400;
  color: #444444;
  line-height: 1.5;
  margin-bottom: 0px;
}

.register-button {
  background-color: #f68b1f;
  color: #ffffff;
  border: none;
  border-radius: 12px;
  padding: 15px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 20px;
  width: 408px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  transition: background-color 0.3s ease;
  text-align: center;
}

.register-button:hover {
  background-color: #e07a15;
  color: white;
}

.register-button:active {
  transform: translateY(1px);
}

@media (max-width: 1100px) {
  .inspection-image-section {
    flex: 0 0 30%;
    max-width: 30%;
  }

  .inspection-content-section {
    flex: 0 0 65%;
    max-width: 65%;
  }
}

@media (max-width: 992px) {
  .inspection-layout {
    flex-direction: column;
    align-items: center;
  }

  .inspection-image-section,
  .inspection-content-section {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .inspection-image-section {
    margin-bottom: 30px;
    text-align: center;
  }

  .inspection-image-section img {
    max-width: 280px;
  }
}

@media (max-width: 768px) {
  .main-title {
    font-size: 22px;
    margin-bottom: 20px;
    text-align: center;
  }

  .inspection-image-section img {
    max-width: 220px;
  }

  .step-title {
    font-size: 20px;
  }

  .step-description {
    font-size: 16px;
  }

  .register-button {
    width: 100%;
    max-width: 90%;
    padding: 15px;
    font-size: 18px;
  }
  .ride-share-title {
    font-size: 14px;
    font-weight: 500;
    padding: 5px 10px;
    border-radius: 58.57px;
  }

  .ride-share-title {
    font-size: 14px;
    padding: 4px 8px;
  }
}

@media (max-width: 480px) {
  .inspection-process {
    padding: 10px;
  }

  .inspection-layout {
    gap: 10px;
  }

  .main-title {
    font-size: 20px;
  }

  .inspection-image-section img {
    max-width: 180px;
  }

  .step-item {
    margin-bottom: 25px;
  }

  .icon-container {
    width: 50px;
    height: 50px;
  }

  .timeline-line {
    left: 34px;
  }

  .step-title {
    font-size: 18px;
  }

  .step-description {
    font-size: 14px;
  }

  .ride-share-title {
    font-size: 12px;
    padding: 3px 7px;
    border-radius: 30px;
  }
}
