/* Backed company section styles */
.backed_company_section_main {
  /* margin: 3rem 0; */
}

.backed_company_section_container {
  width: 90%;
  margin: auto;
}

.text_techStart_para_landing {
  font-size: 18px;
  font-weight: 600;
}

.techStar_LogoCompany {
  margin-bottom: 2rem;
}

.company_backed-new {
  font-weight: bold;
  color: #000000;
  font-size: 26px;
}

.img_techStar {
  object-fit: contain;
  width: 100% !important;
  max-width: 230px;
}

.a_text_landing {
  margin-right: 8px;
}

.companylogo_container {
  width: 100%;
  margin: auto;
}

.item_company_backed_company img {
  /* max-height: 40px; */
  object-fit: contain;
  margin: auto 0px;
}
.item_company_backed_company2 img {
  max-height: 35px;
  object-fit: contain;
  margin: 20px 10px 0 10px;
}
